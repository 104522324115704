import { Pipe, PipeTransform } from '@angular/core';

import { EventTriggerCategory } from 'src/app/features/engagements/pages/engagements-dashboard/engagements-triggers/models';
import { EventTrigger } from 'src/app/shared/models';

@Pipe({
	name: 'eventTriggerFilter',
	standalone: true,
})
export class EventTriggerFilterPipe implements PipeTransform {
	transform(eventTriggers: EventTrigger[], triggerCategory: EventTriggerCategory, hideInactive: boolean): EventTrigger[] {
		if (eventTriggers) {
			return eventTriggers.filter(d => (!triggerCategory || d.eventTriggerCategoryId === triggerCategory.id) && (!hideInactive || d.isActive));
		}

		return eventTriggers;
	}
}
